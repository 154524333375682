import logo from './logo.svg';
import { Routes, Route } from "react-router-dom"
import './App.css';
import Test from './components/Test.js';
import Home from "./components/Home";
import Scan from "./components/Scan";

function App() {
  return (

    <div className="App">
      <Routes>
        <Route path="/" element={ <Scan/> } />
          <Route path="/scan" element={ <Home/> } />
      </Routes>
    </div>
  );
}

export default App;
