import {db} from "./Firestore";
import { doc, getDoc, query, collection, where, getDocs, updateDoc, setDoc } from "firebase/firestore";
import {useState} from "react";

export const getNote = async (id) => {
    const collectionRef = collection(db, "Gifts")
    const q = query(collectionRef, where("genid", "==", id));
    const snapshot = await getDocs(q);


    const results = snapshot.docs.map((doc) => ({...doc.data(), id: doc.id}));


    if (results[0] != null){

        var [gift] = [];




        gift = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

        //
        //console.log(gift)

        return gift


    }
    else{

    }

};

export const sendNote = async (id) => {


    const docRef = doc(db, "Gifts", id);
    const payload = { HasBeenUsed: true };

    setDoc(docRef, payload, { merge: true });
};

