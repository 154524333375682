import React, {Component, useState, useEffect} from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import db from "./Firestore";
import 'bootstrap/dist/css/bootstrap.css';
import {collection, doc, getDocs, query, setDoc, where} from "firebase/firestore";
import Confetti from 'react-dom-confetti';
import QrReader from "react-qr-reader";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBBtn,
    MDBRow,
    MDBCol,
    MDBCardImage,
    MDBCardFooter
} from 'mdb-react-ui-kit';
import {getNote, sendNote} from "./getDoc";
import {Container, Row} from "react-bootstrap";
//import {getNote} from "./getDoc";
class Scan extends Component {
    constructor() {
        super();
        this.state = {
            show: false,
            show2: false,
            setShow: false,
            result: 'No result',
            name: null,
            valuesa: null,
            stage: "1",
            rused: null,
            rrecip: null,
            raction: null,
            rpayload: null,
            confetti: false,
            rreason: null,
            rgenid: null,
            docidd: null
        };


    }

    handleShow = () => {
        this.setState({show: true});
    }
    handleClose = () => {
        this.setState({show: false});
    }

    nameupdate = data => {
        this.setState({name: data});
        console.log(this.state.name)
    }

    confettitrue = () => {
        this.setState({confetti: true});

    }

    activatepayload = () => {

        sendNote(this.state.docidd);

        if (this.state.raction == "redirect"){
            window.location.href = this.state.rpayload;
        }

        if (this.state.raction == "text"){

            this.setState({stage: "3"})
        }



    }


    handleScan = data => {
        if (data) {
            this.setState({
                result: data

            })
            getNote(data).then(r => {
                this.setState({valuesa: r})
                console.log("log has been set")
                console.log(this.state.valuesa)

                if (this.state.valuesa != null){
                    this.handleClose()


                    const nameo = this.state.valuesa.map((data) => (
                        this.setState({rused: data.HasBeenUsed, rrecip: data.Recip, raction: data.action, rpayload: data.payload, rreason: data.reason, rgenid: data.genid, docidd: data.id})

                    ))

                    if (this.state.rused == false){
                        this.setState({stage: "2"})
                    }

                    if (this.state.rused == true){
                        this.setState({stage: "4"})

                    }


                }

            })
















        }
    }
    handleError = err => {
        console.error(err)
    }






    render() {

        if (this.state.stage == "1"){

    return (
        <div className="scan" style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh', backgroundImage: `url("https://go.harw.uk/publicserve/background2.png")`}}>
            <div class="container">
                <div class="row align-items-center my-5">
                    <div class="col-lg-7">
                        <img
                            class="img-fluid rounded mb-4 mb-lg-0"
                            src="https://go.harw.uk/publicserve/jar.jpg"
                            alt=""
                        />


                    </div>
                    <div class="col-lg-5">
                        <h1 class="font-weight-light">Gift Jar</h1>
                        <p>
                          Find out what Gift your getting from Michael today

                        </p>
                        <div>
                            <Button variant="primary" onClick={this.handleShow}>
                                Scan your Jar Code
                            </Button>

                            <Modal show={this.state.show} onHide={this.handleClose}>
                                <Modal.Header>
                                    <Modal.Title>Scan your code</Modal.Title>
                                </Modal.Header>
                                <Modal.Body><QrReader
                                    delay={300}
                                    onError={this.handleError}
                                    onScan={this.handleScan}
                                    style={{ width: '100%' }}
                                /></Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={this.handleClose}>
                                        Cancel
                                    </Button>

                                </Modal.Footer>
                            </Modal>
                            <Modal show={this.state.show2} onHide={this.handleClose}>
                                <Modal.Header>
                                    <Modal.Title>Scan your code</Modal.Title>
                                </Modal.Header>
                                <Modal.Body></Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={this.handleClose}>
                                        Cancel
                                    </Button>

                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) }

        const config = {
            angle: 0,
            spread: 360,
            startVelocity: 40,
            elementCount: 70,
            dragFriction: 0.12,
            duration: 3000,
            stagger: 3,
            width: "10px",
            height: "10px",
            perspective: "500px",
            colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
        };

        if (this.state.stage == "2"){
            return (

                <MDBRow className='row-cols-1 row-cols-md-3 g-4 align-self-center'style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh', backgroundImage: `url("https://go.harw.uk/publicserve/background2.png")`}}>
                    <MDBCol>

                    </MDBCol>
                    <MDBCol>
                        <MDBCard className='h-100 align-self-center'>
                            <MDBCardImage
                                src='https://go.harw.uk/publicserve/jar.jpg'
                                alt='...'
                                position='top'
                            />
                            <MDBCardBody>
                                <MDBCardTitle>A Gift for... {this.state.rrecip}</MDBCardTitle>
                                <MDBCardText>
                                   So? Your feeling {this.state.rreason}
                                </MDBCardText>
                            </MDBCardBody>
                            <MDBCardFooter>
                                <Confetti active={ this.state.confetti} />
                                <Button variant="primary" size="lg" onClick={this.activatepayload}>
                                    Get your Gift
                                </Button>

                            </MDBCardFooter>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol>

                    </MDBCol>
                </MDBRow>
            )
        }

        if (this.state.stage == "4"){
            return (

                <MDBRow className='row-cols-1 row-cols-md-3 g-4 align-self-center'style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh', backgroundImage: `url("https://go.harw.uk/publicserve/background2.png")`}}>
                    <MDBCol>

                    </MDBCol>
                    <MDBCol>
                        <MDBCard className='h-100 align-self-center'>
                            <MDBCardImage
                                src='https://go.harw.uk/publicserve/jar.jpg'
                                alt='...'
                                position='top'
                            />
                            <MDBCardBody>
                                <MDBCardTitle>A Gift for... {this.state.rrecip}</MDBCardTitle>
                                <MDBCardText>
                                    Oh look, you've already seen this gift. But don't worry, I am nice, you can see it again...

                                    So? Your feeling {this.state.rreason}
                                </MDBCardText>
                            </MDBCardBody>
                            <MDBCardFooter>
                                <Confetti active={ this.state.confetti} />
                                <Button variant="primary" size="lg" onClick={this.activatepayload}>
                                    See your gift again
                                </Button>

                            </MDBCardFooter>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol>

                    </MDBCol>
                </MDBRow>
            )
        }

        if (this.state.stage == "3"){
            return (

                <MDBRow className='row-cols-1 row-cols-md-3 g-4 align-self-center' style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh', backgroundImage: `url("https://go.harw.uk/publicserve/background.jpg")`}}>
                    <MDBCol>

                    </MDBCol>
                    <MDBCol>
                        <MDBCard className='h-100 align-self-center'>
                            <MDBCardImage
                                src='https://go.harw.uk/publicserve/jar.jpg'
                                alt='...'
                                position='top'
                            />
                            <MDBCardBody>
                                <MDBCardTitle>A Gift for... {this.state.rrecip}</MDBCardTitle>
                                <MDBCardText>
                                    {this.state.rpayload}
                                </MDBCardText>
                            </MDBCardBody>
                        
                        </MDBCard>
                    </MDBCol>
                    <MDBCol>

                    </MDBCol>
                </MDBRow>
            )
        }

    }
}


export default Scan;