// Import the functions you need from the SDKs you need
import { getFirestore } from 'firebase/firestore';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDasq_pfjo4QvpdUDKLTx_kTi6rbnDPGbY",
    authDomain: "yo-presents.firebaseapp.com",
    databaseURL: "https://yo-presents-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "yo-presents",
    storageBucket: "yo-presents.appspot.com",
    messagingSenderId: "162947566441",
    appId: "1:162947566441:web:e9387602d7942089f98bf8",
    measurementId: "G-87YKCC28RQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
// eslint-disable-next-line no-undef

export const db = getFirestore(app);
export default app

